import axios from "axios";
import { getCurrentUser, setCurrentUser } from 'helpers/Utils';

// export const apiURL = (authorization = true) => {
//     console.log('apiURL')
//     if(!authorization) {
//         return axios.create({
//             baseURL: process.env.REACT_APP_API,
//         });
//     } else {
//         return axios.create({
//             baseURL: process.env.REACT_APP_API,
//             headers: {
//                 // 'Access-Control-Allow-Origin' : '*',
//                 // 'Access-Control-Allow-Headers': '*',
//                 'Authorization': 'Bearer '+getCurrentUser().token
//             }
//         });
//     }
// }

export const apiURLAuth = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        'Authorization': 'Bearer '+getCurrentUser()?.token
    }
});

export const apiURL = axios.create({
    baseURL: process.env.REACT_APP_API,
});

export const apiCaptcha = axios.create({
    baseURL: `https://www.google.com/recaptcha/api`,
    // baseURL: `https://www.google.com/recaptcha/api/siteverify?secret=${process.env.REACT_APP_SECRET_KEY}&response=${token}`,
});



apiURLAuth.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if(error.response.status === 401) {
            if(error.response.data.error === 'Wrong number of segments') {
                window.location.reload();
            } else if(error.response.data.error === 'Expired token') {
                setCurrentUser()
                window.location = '/user/login';
            }
        } 
        return Promise.reject(error);
});