import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from 'helpers/Firebase';
import { Authentication, sendPasswordResetEmail, confirmPasswordReset } from 'helpers/AuthAPI';
import { adminRoot, currentUser } from 'constants/defaultValues';
import { setCurrentUser } from 'helpers/Utils';
import {
	LOGIN_USER,
	REGISTER_USER,
	LOGOUT_USER,
	FORGOT_PASSWORD,
	RESET_PASSWORD,
} from '../contants';

import {
	loginUserSuccess,
	loginUserError,
	registerUserSuccess,
	registerUserError,
	forgotPasswordSuccess,
	forgotPasswordError,
	resetPasswordSuccess,
	resetPasswordError,
} from './actions';

export function* watchLoginUser() {
	// eslint-disable-next-line no-use-before-define
	yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
	await Authentication(email, password)
	.then((user) => user)
	.catch((error) => error);

function* loginWithEmailPassword({ payload }) {
	const { email, password } = payload.user;
	const { history } = payload;
	try {
		const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
		if (!loginUser.message) {
			const item = { uid: loginUser.user.id, token: loginUser.token, ...loginUser.user };
			setCurrentUser(item);
			yield put(loginUserSuccess(item));
			history.push(adminRoot);
		} else {
			yield put(loginUserError(loginUser.message));
			yield put(loginUserError());
		}
	} catch (error) {
		yield put(loginUserError(error));
		yield put(loginUserError());
	}
}

export function* watchRegisterUser() {
	// eslint-disable-next-line no-use-before-define
	yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
	// eslint-disable-next-line no-return-await
	await auth
		.createUserWithEmailAndPassword(email, password)
		.then((user) => user)
		.catch((error) => error);

function* registerWithEmailPassword({ payload }) {
	const { email, password } = payload.user;
	const { history } = payload;
	try {
		const registerUser = yield call(
			registerWithEmailPasswordAsync,
			email,
			password
		);
		if (!registerUser.message) {
			const item = { uid: registerUser.user.uid, ...currentUser };
			setCurrentUser(item);
			yield put(registerUserSuccess(item));
			history.push(adminRoot);
		} else {
			yield put(registerUserError(registerUser.message));
			yield put(registerUserError());
		}
	} catch (error) {
		yield put(registerUserError(error));
		yield put(registerUserError());
	}
}

export function* watchLogoutUser() {
	// eslint-disable-next-line no-use-before-define
	yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  	await auth
		.signOut()
		.then((user) => user)
		.catch((error) => error);
		history.push(adminRoot);
};

function* logout({ payload }) {
	const { history } = payload;
	setCurrentUser();
	yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
	// eslint-disable-next-line no-use-before-define
	yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) =>
	// eslint-disable-next-line no-return-await
	await sendPasswordResetEmail(email)
		.then((user) => user)
		.catch((error) => error);

function* forgotPassword({ payload }) {
	const { email } = payload.forgotUserMail;
	try {
		const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
		if (forgotPasswordStatus.success) {
			yield put(forgotPasswordSuccess('success'));
		} else {
			yield put(forgotPasswordError(forgotPasswordStatus.message));
			yield put(forgotPasswordError());
		}
	} catch (error) {
		yield put(forgotPasswordError(error));
		yield put(forgotPasswordError());
	}
}

export function* watchResetPassword() {
	// eslint-disable-next-line no-use-before-define
	yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) =>
	await confirmPasswordReset(resetPasswordCode, newPassword)
		.then((user) => user)
		.catch((error) => error);

function* resetPassword({ payload }) {
	const { newPassword, resetPasswordCode } = payload;
	try {
		const resetPasswordStatus = yield call(
			resetPasswordAsync,
			resetPasswordCode,
			newPassword
		);
		if (resetPasswordStatus.success) {
			yield put(resetPasswordSuccess('success'));
		} else {
			yield put(resetPasswordError(resetPasswordStatus.message));
			yield put(resetPasswordError());
		}
	} catch (error) {
		yield put(resetPasswordError(error));
		yield put(resetPasswordError());
	}
}

export default function* rootSaga() {
	yield all([
		fork(watchLoginUser),
		fork(watchLogoutUser),
		fork(watchRegisterUser),
		fork(watchForgotPassword),
		fork(watchResetPassword),
	]);
}
