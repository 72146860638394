import React from 'react';
import { apiURLAuth } from './api';

export const Conversation = async () => {
    return await apiURLAuth.get('chat')
    .then(async function (response) {
        return response.data
    })
    .catch(function (error) {
        return error.response.data
    });
};

export const Users = async () => {
    return await apiURLAuth.get('chatUsers')
    .then(async function (response) {
        return response.data
    })
    .catch(function (error) {
        return error.response.data
    });
};